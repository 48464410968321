import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  useNavigate,
  useLocation,
} from "react-router-dom";
import EnterLostDisc from "./EnterLostDisc";
import Inventory from "./Inventory";
import ForSaleInventory from "./ForSaleInventory";
import {
  Button,
  useMediaQuery,
  useTheme,
  Menu,
  MenuItem,
  Divider,
  Alert,
  Snackbar,
  IconButton,
} from "@mui/material"; // Import Button and ButtonGroup from MUI
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import "../globals.css";
import DensityMediumIcon from "@mui/icons-material/DensityMedium";
import fullTransparentLogo1700 from "../Images/full_logo_transparent_1740x300.png";
import fullTransparentLogoFullSized from "../Images/full_logo_transparent_fullsize.png";
import iconLogoTransparent300 from "../Images/icon_logo_transparent_300x300.png";
import iconLogoTransparentFullSized from "../Images/icon_logo_transparent_fullsize.png";
import textLogoTransparent300 from "../Images/text_logo_transparent_1690x300.png";
import textLogoTransparentFullSized from "../Images/text_logo_transparent_fullsize.png";
import MenuButton from "@mui/joy/MenuButton";
import Dropdown from "@mui/joy/Dropdown";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { Disc, openAdminDashboard } from "../App";
import { get } from "http";
import LoggedOut from "./LoggedOut";
import ErrorDialog from "./ErrorDialog";
import Banner from "./Banner";
import NoOrg from "./NoOrg";

export interface Course {
  orgCode: string;
  courseName: string;
}

export interface DemoProps {
  discArray: Disc[];
  setDiscArray: (discArray: Disc[]) => void;
}

function AdminPanel(props: DemoProps) {
  const [activeTab, setActiveTab] = useState("enterLostDisc"); // Default active tab
  const location = useLocation();
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("md"));
  const [course, setCourse] = useState<Course>({
    orgCode: "org_c1d3bb10091e7",
    courseName: "Demo",
  });
  const [bannerOpen, setBannerOpen] = useState(true); // Banner open state
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const clearSuccessMessage = () => {
    setShowSuccessMessage(false);
    setSuccessMessage("");
  };

  const clearErrorMessage = () => {
    setShowErrorMessage(false);
    setErrorMessage("");
  };

  const hashPassword = (password: string) => {
    return CryptoJS.SHA256(password).toString();
  };

  const switchTab = (tabName: string) => {
    setActiveTab(tabName);
  };

  const handleClosePopup = () => setShowErrorMessage(false);

  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = React.useState<number>(1);
  const [lastIndex, setLastIndex] = React.useState<number>(-1);

  const createHandleClose = (index: number) => () => {
    setLastIndex(selectedIndex);
    if (typeof index === "number") {
      setSelectedIndex(index);
    }
    if (index === 3) {
      navigate("/");
    }
    setAnchorEl(null);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowErrorMessage(false);
  };

  const refresh = () => {
    // window.location.reload();
    navigate("/");
  };

  return (
    <div className="App">
      <Banner open={bannerOpen} setOpen={setBannerOpen} />
      <header className="App-header">
        <img
          src={fullTransparentLogo1700}
          alt="DRN-Logo"
          className="logo-300"
          onClick={() => refresh()}
        />
        <IconButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          sx={{ position: "absolute", right: "20px" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            // className="Navbar-menu-icon"
            viewBox="0 0 24 24"
            style={{
              fill: "rgba(0, 0, 0, 1)",
              margin: "auto",
            }}
          >
            <path d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z"></path>
          </svg>
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem>Welcome, Demo User</MenuItem>

          <MenuItem onClick={openAdminDashboard}>Sign Up</MenuItem>

          <Divider />

          <MenuItem
            {...(selectedIndex === 0 && {
              selected: true,
              variant: "soft",
            })}
            onClick={createHandleClose(0)}
          >
            Enter Lost Disc
          </MenuItem>

          <MenuItem
            selected={selectedIndex === 1}
            onClick={createHandleClose(1)}
          >
            Inventory
          </MenuItem>

          <MenuItem
            selected={selectedIndex === 2}
            onClick={createHandleClose(2)}
          >
            For Sale
          </MenuItem>
        </Menu>
      </header>

      <main className="container">
        {selectedIndex === 0 && (
          <EnterLostDisc
            course={course}
            setSelectedIndex={setSelectedIndex}
            createHandleClose={() => createHandleClose}
            lastIndex={lastIndex}
            setLastIndex={setLastIndex}
            discArray={props.discArray}
            setDiscArray={props.setDiscArray}
          />
        )}
        {selectedIndex === 1 && (
          <Inventory
            course={course}
            setSelectedIndex={setSelectedIndex}
            createHandleClose={() => createHandleClose}
            lastIndex={lastIndex}
            setLastIndex={setLastIndex}
            discArray={props.discArray}
            setDiscArray={props.setDiscArray}
          />
        )}
        {selectedIndex === 2 && (
          <ForSaleInventory
            course={course}
            setSelectedIndex={setSelectedIndex}
            createHandleClose={() => createHandleClose}
            lastIndex={lastIndex}
            setLastIndex={setLastIndex}
            discArray={props.discArray}
            setDiscArray={props.setDiscArray}
          />
        )}
      </main>

      <Snackbar
        open={showErrorMessage}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default AdminPanel;
