// PopupComponent.tsx
import React, { useEffect, useRef, useState } from "react";
import "../styles/ImageDetectionPopup.css";
import "../globals.css";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";

interface PopupProps {
  data: Array<{ text: string; category: string }>;
  onClose: () => void;
  onUpdateCategory: (index: number, category: string) => void;
  onUpdateText: (index: number, text: string) => void;
  onDelete: (index: number) => void;
  prefillForm: () => void;
  categories: Array<string>;
}

const ImageDetectionPopup: React.FC<PopupProps> = ({
  data,
  onClose,
  onUpdateCategory,
  onUpdateText,
  onDelete,
  prefillForm,
  categories,
}) => {
  const popupRef = useRef<HTMLDivElement>(null);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [editedText, setEditedText] = useState<string>("");
  const [isAnyCategorySelect, setIsAnyCategorySelect] =
    useState<boolean>(false);
  const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  };
  const startEditing = (text: string, index: number) => {
    setEditingIndex(index);
    setEditedText(text);
  };

  const saveEditing = () => {
    // Call your onUpdateCategory function with the updated text and editingIndex
    if (editingIndex !== null) {
      onUpdateText(editingIndex, editedText);
    }
    // Clear editing state
    setEditingIndex(null);
    setEditedText("");
  };

  // const handleClickOutside = (event: MouseEvent) => {
  //   console.log("click outside");
  //   if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
  //     onClose();
  //   }
  // };

  useEffect(() => {
    // Check if any category is "SELECT"
    console.log(data);
    const hasSelectCategory = data.some(
      (item) =>
        item.category === "" ||
        item.category === "SELECT" ||
        item.category === "Select Category" ||
        item.category === "Unknown"
    );
    setIsAnyCategorySelect(hasSelectCategory);
  }, [data]);

  return (
    <div className="popup-container">
      <div className="popup-content" ref={popupRef}>
        <p className="disclaimer">
          Note: The AI Integration is turned off for this demo. The model is
          always learning and the demo is an uncontrolled dataset. If you're
          interested in seeing the full capabilities, please contact us.
        </p>
        <table className="popup-table">
          <colgroup>
            <col style={{ width: "75%" }} />
            <col style={{ width: "25%" }} />
          </colgroup>
          <thead>
            <tr>
              <th>Captured Text</th>
              <th>Category</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="text"
                    value={item.text}
                    onChange={(e) => onUpdateText(index, e.target.value)}
                    className="popup-textfield"
                  />
                </td>
                <td className="category-row">
                  <select
                    value={item.category}
                    className="popup-select"
                    onChange={(e) => onUpdateCategory(index, e.target.value)}
                  >
                    {categories.map((category, categoryIndex) => (
                      <option key={categoryIndex} value={category}>
                        {category}
                      </option>
                    ))}
                  </select>
                  <DeleteIcon
                    onClick={() => onDelete(index)} // Call onDelete with the index
                    sx={{
                      margin: "0px",
                      padding: "0px",
                      marginLeft: "7.5px",
                      cursor: "pointer",
                      fontSize: "1rem",
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          onClick={onClose}
          className="close-button"
        >
          <path d="M9.172 16.242 12 13.414l2.828 2.828 1.414-1.414L13.414 12l2.828-2.828-1.414-1.414L12 10.586 9.172 7.758 7.758 9.172 10.586 12l-2.828 2.828z"></path>
          <path d="M12 22c5.514 0 10-4.486 10-10S17.514 2 12 2 2 6.486 2 12s4.486 10 10 10zm0-18c4.411 0 8 3.589 8 8s-3.589 8-8 8-8-3.589-8-8 3.589-8 8-8z"></path>
        </svg>
        <div className="row-end">
          <Button
            disabled={editingIndex != null || isAnyCategorySelect}
            onClick={prefillForm}
            className={
              editingIndex != null || isAnyCategorySelect
                ? "button-submit-disabled"
                : "button-submit"
            }
          >
            Submit to Form
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ImageDetectionPopup;
