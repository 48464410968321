import React, { useCallback, useEffect, useState } from "react";
import { Disc, DiscStateString } from "../App";
import "../globals.css";
import "../styles/Inventory.css";
import { DateTime } from "luxon";
import DeleteIcon from "@mui/icons-material/Delete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import BackHandOutlinedIcon from "@mui/icons-material/BackHandOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import {
  faCircle,
  faSquareCaretUp,
  faSquareCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import {
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Popover,
  Select,
  Snackbar,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import EditDialog from "./EditDialog";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { SelectChangeEvent } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import BackToTopButton from "./BackToTopButton";
import PullToRefresh from "react-simple-pull-to-refresh";
import DeleteConfirmationPopup from "./DeleteConfirmationPopup";
import Legend, { DiscCounts } from "./Legend";
import { Course } from "./AdminPanel";

// Define a type for row IDs, assuming it's a number
type RowId = number;

export interface courseAndNavProps {
  course: Course;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
  createHandleClose: () => void;
  lastIndex: number;
  setLastIndex: React.Dispatch<React.SetStateAction<number>>;
  discArray: Disc[];
  setDiscArray: (discArray: Disc[]) => void;
}

function Inventory(props: courseAndNavProps) {
  const [inventory, setInventory] = useState<Disc[]>([]); // Provide the type 'Disc[]'
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredInventory, setFilteredInventory] = useState(inventory); // Initialize with inventory data
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [informationalMessage, setInformationalMessage] = useState("");
  const [showInformationalMessage, setShowInformationalMessage] =
    useState(false);
  const [claimedDisc, setClaimedDisc] = useState<number>(0); // Provide the type 'Disc | null'
  const [sortOption, setSortOption] = useState<keyof Disc>("claimBy"); // Set initial sort option
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc"); // Set initial sort direction to DESC
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("md"));
  const [counts, setCounts] = useState<DiscCounts>({
    needsReview: 0,
    new: 0,
    unclaimed: 0,
  });
  // const course = process.env.REACT_APP_COURSE_NAME!;

  const {
    course,
    setSelectedIndex,
    createHandleClose,
    lastIndex,
    setLastIndex,
    discArray,
    setDiscArray,
  } = props;

  const handleRefresh = async () => {
    getInventory(course.courseName);
  };

  const clearSuccessMessage = () => {
    setShowSuccessMessage(false);
    setSuccessMessage("");
  };

  const clearErrorMessage = () => {
    setShowErrorMessage(false);
    setErrorMessage("");
  };

  const clearInformationalMessage = () => {
    setShowInformationalMessage(false);
    setInformationalMessage("");
  };

  // const [expandedRows, setExpandedRows] = useState<RowId[]>([]);

  // const toggleRow = (rowId: RowId) => {
  //   if (expandedRows.includes(rowId)) {
  //     setExpandedRows(expandedRows.filter((id) => id !== rowId));
  //   } else {
  //     setExpandedRows([...expandedRows, rowId]);
  //   }
  // };

  const [expandedRow, setExpandedRow] = useState<RowId | null>(null);

  const toggleRow = (rowId: RowId) => {
    // If the clicked row is already expanded, collapse it
    if (expandedRow === rowId) {
      setExpandedRow(null);
    } else {
      // Otherwise, expand the clicked row and collapse the previous one
      setExpandedRow(rowId);
    }
  };

  const convertToEST = (utcTimestamp: string) => {
    const dateUTC = DateTime.fromISO(utcTimestamp, { zone: "utc" });
    // const dateEST = dateUTC.setZone('America/New_York');

    // Format the date to display only the date (without time)
    //return dateEST.toFormat('yyyy-MM-dd');
    return dateUTC.toFormat("yyyy-MM-dd");
  };

  const [anchorElPopover, setAnchorElPopover] =
    useState<HTMLButtonElement | null>(null);

  const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log("filter button clicked");
    setAnchorElPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorElPopover(null);
  };

  const openPopover = Boolean(anchorElPopover);
  const idPopover = openPopover ? "simple-popover" : undefined;
  const [isNewFilter, setIsNewFilter] = useState(false);
  const [isUnclaimedFilter, setIsUnclaimedFilter] = useState(false);
  const [isOverdueFilter, setIsOverdueFilter] = useState(false);

  const setFilter = (filterIn: string) => {
    switch (filterIn) {
      case "New":
        setIsNewFilter(!isNewFilter);
        setIsUnclaimedFilter(false);
        setIsOverdueFilter(false);
        break;
      case "Unclaimed":
        setIsNewFilter(false);
        setIsUnclaimedFilter(!isUnclaimedFilter);
        setIsOverdueFilter(false);
        break;
      case "Overdue":
        setIsNewFilter(false);
        setIsUnclaimedFilter(false);
        setIsOverdueFilter(!isOverdueFilter);
        break;
      default:
        setIsNewFilter(false);
        setIsUnclaimedFilter(false);
        setIsOverdueFilter(false);
        break;
    }
  };

  const getInventory = (course: string) => {
    setIsLoading(true);
    const convertedInventory = discArray
      .filter(
        (disc: Disc) =>
          disc.status !== DiscStateString.ForSale &&
          disc.status !== DiscStateString.Sold &&
          disc.status !== DiscStateString.Claimed &&
          disc.status !== DiscStateString.Surrendered
      )
      .map((disc: Disc) => ({
        ...disc,
        dateFound: convertToEST(disc.dateFound),
        dateTexted: disc.dateTexted ? convertToEST(disc.dateTexted) : null,
        dateClaimed: disc.dateClaimed ? convertToEST(disc.dateClaimed) : null,
        claimBy: disc.claimBy ? convertToEST(disc.claimBy) : null,
      }));
    //console.log('Inventory:', convertedInventory);

    setInventory(convertedInventory);

    // Initialize counts
    let needsReviewCount = 0;
    let newCount = 0;
    let unclaimedCount = 0;

    convertedInventory.forEach((disc: Disc) => {
      if (disc.status === DiscStateString.New) {
        newCount += 1;
      } else if (new Date(disc.claimBy!) < new Date()) {
        needsReviewCount += 1;
      } else if (
        disc.status === DiscStateString.Unclaimed &&
        new Date(disc.claimBy!) >= new Date()
      ) {
        // Assuming "unclaimed" is a valid status value
        unclaimedCount += 1;
      }
    });

    // Update state with the new counts
    setCounts({
      needsReview: needsReviewCount,
      new: newCount,
      unclaimed: unclaimedCount,
    });

    const sortedInventory = [...convertedInventory].sort((a: Disc, b: Disc) => {
      const aValue = a[sortOption] as string; // Cast to string
      const bValue = b[sortOption] as string; // Cast to string

      if (sortDirection === "asc") {
        return aValue.localeCompare(bValue);
      } else {
        return bValue.localeCompare(aValue);
      }
    });

    // setFilteredInventory(filtered);
    const filteredInventory = sortedInventory.filter((disc: Disc) => {
      const isMatch =
        disc.phoneNumber.includes(searchQuery) ||
        disc.disc.toLowerCase().includes(searchQuery.toLowerCase()) ||
        disc.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        disc.brand?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        disc.comments?.toLowerCase().includes(searchQuery.toLowerCase());

      // Check for New status
      if (isNewFilter) {
        return isMatch && disc.status === DiscStateString.New;
      }

      // Check for Overdue
      if (isOverdueFilter) {
        return isMatch && new Date(disc.claimBy!) < new Date();
      }

      // Check for Unclaimed
      if (isUnclaimedFilter) {
        const isNotNew = disc.status !== DiscStateString.New;
        const isNotOverdue = new Date(disc.claimBy!) >= new Date();
        return isMatch && isNotNew && isNotOverdue;
      }

      // Default return if no filter is applied
      return isMatch;
    });

    setFilteredInventory(filteredInventory);
    setIsLoading(false);
  };

  useEffect(() => {
    getInventory(course.courseName);
  }, [
    course,
    searchQuery,
    sortDirection,
    sortOption,
    isNewFilter,
    isOverdueFilter,
    isUnclaimedFilter,
    discArray,
  ]);

  const markAsClaimed = (discId: string) => {
    setIsLoading(true); // Set loading state to true

    try {
      discArray.find((disc) => disc.id === parseInt(discId))!.status = // Find the disc in the array
        DiscStateString.Claimed; // Change the status to "Claimed"
      setIsLoading(false); // Set loading state to false
      setSuccessMessage("Disc claimed successfully"); // Set success message
      setShowSuccessMessage(true); // Show success message
      getInventory(course.courseName);
      setClaimedDisc(parseInt(discId)); // Set claimedDisc to the ID of the disc being marked as claimed
    } catch (error) {
      console.error("Error marking disc as claimed:", error);
      setIsLoading(false); // Set loading state to false in case of an error
      setErrorMessage("Error marking disc as claimed"); // Set error message
      setShowErrorMessage(true); // Show error message
    }
  };

  const [editedDiscID, setEditedDiscID] = useState<number>(-1);
  const [editedDisc, setEditedDisc] = useState<Disc | null>(null);

  const startEditing = (disc: Disc) => {
    // Create a deep copy of the disc to edit
    const discCopy = JSON.parse(JSON.stringify(disc));
    setEditedDisc(discCopy);
    setEditedDiscID(disc.id!);
  };

  const stopEditing = () => {
    saveEditedDisc(editedDisc!);
  };

  const saveEditedDisc = (editedDiscIn: Disc) => {
    // Find the original disc from the inventory using the edited disc's ID
    const originalDisc = inventory.find((disc) => disc.id === editedDiscIn.id);

    console.log("Original disc:", originalDisc);
    console.log("Edited disc:", editedDiscIn);

    try {
      // Function to compare the original and edited discs
      const hasChanges = (original: Disc | undefined, edited: Disc) => {
        if (!original) return true; // If for some reason the original disc isn't found, assume changes
        // Compare each field that could be edited. Add more fields as necessary.
        return (
          original.name !== edited.name ||
          original.color !== edited.color ||
          original.phoneNumber !== edited.phoneNumber ||
          original.brand !== edited.brand ||
          original.disc !== edited.disc ||
          original.bin !== edited.bin ||
          original.comments !== edited.comments ||
          original.dateFound !== edited.dateFound ||
          original.dateTexted !== edited.dateTexted ||
          original.dateClaimed !== edited.dateClaimed ||
          original.claimBy !== edited.claimBy ||
          original.status !== edited.status ||
          original.course !== edited.course
        );
      };

      // Proceed only if changes were detected
      if (hasChanges(originalDisc, editedDiscIn)) {
        console.log("Changes detected, proceeding with update.");
        setIsLoading(true);
        const discArrayTmp = discArray.map((disc) =>
          disc.id === editedDiscIn.id ? editedDiscIn : disc
        );

        setDiscArray(
          discArrayTmp.map((disc) =>
            disc.id === editedDiscIn.id ? editedDiscIn : disc
          )
        );

        console.log("Disc updated:", editedDiscIn);
        setSuccessMessage("Disc updated successfully");
        setShowSuccessMessage(true);
        getInventory(course.courseName);
      } else {
        console.log("No changes detected, skipping update.");
        setInformationalMessage("No changes detected, skipping update.");
        setShowInformationalMessage(true);
      }
    } catch (error) {
      console.error("Error marking disc as claimed:", error);
      setIsLoading(false); // Set loading state to false in case of an error
      setErrorMessage("Error marking disc as claimed"); // Set error message
      setShowErrorMessage(true); // Show error message
    }
    setEditedDisc(null);
    setEditedDiscID(-1);
  };

  const listForSale = (discId: string, course: string) => {
    setIsLoading(true);
    console.log("Marking as for sale");

    try {
      discArray.find((disc) => disc.id === parseInt(discId))!.status = // Find the disc in the array
        DiscStateString.ForSale; // Change the status to "For Sale"
      setIsLoading(false); // Set loading state to false
      setSuccessMessage("Disc Listed For Sale"); // Set success message
      setShowSuccessMessage(true); // Show success message
      getInventory(course);
    } catch (error) {
      console.error("Error listing disc for sale:", error);
      setIsLoading(false); // Set loading state to false in case of an error
      setErrorMessage("Error listing disc for sale"); // Set error message
      setShowErrorMessage(true); // Show error message
    }
  };

  const formatPhoneNumber = (phoneNumber: string) => {
    // Assuming phoneNumber is in the format "1234567890"
    return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  };

  const handleSort = (selectedOption: keyof Disc) => {
    if (selectedOption === sortOption) {
      // Toggle sort direction
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortDirection("asc"); // Default to ascending if a new option is selected
    }
    setSortOption(selectedOption);
  };

  // Define a function to render the header with sorting indicator
  const renderColumnHeader = (column: keyof Disc, label: string) => {
    const isSorted = column === sortOption;
    const isAscending = sortDirection === "asc";
    const arrow = isSorted ? (isAscending ? "▲" : "▼") : null;

    return (
      <th className="table-header" onClick={() => handleSort(column)}>
        {label} {arrow}
      </th>
    );
  };

  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const handleDeleteClick = () => {
    console.log("Delete clicked");
    setShowDeletePopup(true);
  };

  const handleClose = () => {
    setShowDeletePopup(false);
  };

  const goToReportLostDisc = () => {
    setSelectedIndex(0);
    setLastIndex(1);
    createHandleClose();
  };

  const handleConfirmDelete = (deletedDisc: Disc) => {
    setIsLoading(true);
    console.log("Deleting disc");
    setShowDeletePopup(false);

    try {
      const discArrayTmp = discArray.filter(
        (disc) => disc.id !== deletedDisc.id
      );

      setDiscArray(discArrayTmp);
      setIsLoading(false); // Set loading state to false
      setSuccessMessage("Disc Deleted Successfully"); // Set success message
      setShowSuccessMessage(true); // Show success message
      getInventory(course.courseName);
    } catch (error) {
      console.error("Error marking disc as claimed:", error);
      setIsLoading(false); // Set loading state to false in case of an error
      setErrorMessage("Error marking disc as claimed"); // Set error message
      setShowErrorMessage(true); // Show error message
    }
  };

  const [lastIndexPageName, setLastIndexPageName] = useState<string>("");

  useEffect(() => {
    if (lastIndex === 0) {
      setLastIndexPageName("Enter Lost Disc");
    } else if (lastIndex === 1) {
      setLastIndexPageName("Inventory");
    } else if (lastIndex === 2) {
      setLastIndexPageName("For Sale");
    } else {
      setLastIndexPageName("Error");
    }
  }, [lastIndex]);

  return (
    <Box
      sx={{
        height: "auto",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      {lastIndex !== -1 && (
        <div className="row-back">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="back-icon"
            onClick={() => {
              setLastIndex(-1);
              setSelectedIndex(lastIndex);
              createHandleClose();
            }}
          >
            <path d="M21 11H6.414l5.293-5.293-1.414-1.414L2.586 12l7.707 7.707 1.414-1.414L6.414 13H21z"></path>
          </svg>
          <p
            className="lastIndexPageName"
            onClick={() => {
              setLastIndex(-1);
              setSelectedIndex(lastIndex);
              createHandleClose();
            }}
          >
            {lastIndexPageName}
          </p>
        </div>
      )}
      <h1 className="header">{course.courseName} L & F</h1>
      <PullToRefresh className="ptr-override" onRefresh={handleRefresh}>
        <div className="page-container">
          <div className="col-center">
            <div className="inventory-count">
              Total Discs: {filteredInventory.length}
            </div>
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                marginTop: "5px",
                width: "100%",
                maxWidth: isMobile ? "85%" : "90%",
              }}
            >
              <IconButton
                sx={{ p: "10px" }}
                aria-label="menu"
                onClick={handleClickPopover}
              >
                <FilterListOutlinedIcon />
              </IconButton>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <InputBase
                sx={{ ml: 1, flex: 1, fontSize: "12px" }}
                placeholder="Search by id, name, disc brand, number & more"
                onChange={(e) => {
                  const inputQuery = e.target.value;

                  // Check if the input matches a phone number pattern (e.g., XXXX-XXXX-1234)
                  const isPhoneNumber = /^\d{4}-\d{4}-\d{4}$/.test(inputQuery);

                  // If it's a phone number, use the last 4 digits; otherwise, use the entire query
                  const filteredQuery = isPhoneNumber
                    ? inputQuery.slice(-4)
                    : inputQuery;

                  setSearchQuery(filteredQuery);
                }}
                value={searchQuery}
                type="text"
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>

            <Popover
              id={idPopover}
              open={openPopover}
              anchorEl={anchorElPopover}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Box className={isMobile ? "filter-row-mobile" : "filter-row"}>
                <Chip
                  variant="outlined"
                  label="New"
                  className={
                    isNewFilter ? "filter-button-selected" : "filter-button"
                  }
                  avatar={
                    <FontAwesomeIcon
                      icon={faCircle}
                      style={{ color: "orange", width: "20px", height: "20px" }}
                    />
                  }
                  onClick={() => setFilter("New")}
                />
                <Chip
                  variant="outlined"
                  label="Unclaimed"
                  className={
                    isUnclaimedFilter
                      ? "filter-button-selected"
                      : "filter-button"
                  }
                  avatar={
                    <FontAwesomeIcon
                      icon={faCircle}
                      style={{ color: "yellow", width: "20px", height: "20px" }}
                    />
                  }
                  onClick={() => setFilter("Unclaimed")}
                />
                <Chip
                  variant="outlined"
                  label="Needs Review"
                  className={
                    isOverdueFilter ? "filter-button-selected" : "filter-button"
                  }
                  avatar={
                    <FontAwesomeIcon
                      icon={faCircle}
                      style={{ color: "red", width: "20px", height: "20px" }}
                    />
                  }
                  onClick={() => setFilter("Overdue")}
                />
              </Box>
            </Popover>
            <Box className="row-between">
              <Legend counts={counts} />
              <Button
                variant="contained"
                onClick={goToReportLostDisc}
                className="small-blue-button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                  style={{
                    fill: "rgba(255, 255, 255, 1)",
                    marginRight: "5px",
                    transform: "",
                    msFilter: "",
                  }}
                >
                  <path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z"></path>
                </svg>
                Enter Lost Disc{" "}
              </Button>
            </Box>
          </div>
          <div className="container">
            <div className="table-container">
              <table
                className="inventory-table"
                style={{ tableLayout: "fixed" }}
              >
                <colgroup>
                  <col style={{ width: "30px" }} />
                  <col style={{ width: "33%" }} />
                  <col style={{ width: "33%", minWidth: "150px" }} />
                  <col style={{ width: "33%" }} />
                </colgroup>
                <thead>
                  <tr>
                    <th className="table-header" />
                    {renderColumnHeader("name", "Name")}
                    {renderColumnHeader("claimBy", "Claim By")}
                    {renderColumnHeader("disc", "Disc")}
                  </tr>
                </thead>
                <tbody>
                  {filteredInventory.map((disc) => (
                    <React.Fragment key={disc.id}>
                      <tr onClick={() => toggleRow(disc.id!)}>
                        <td className="table-cell">
                          {expandedRow === disc.id ? (
                            <FontAwesomeIcon
                              icon={faSquareCaretUp}
                              className="icon"
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faSquareCaretDown}
                              className="icon"
                            />
                          )}
                        </td>
                        <td className="table-cell">
                          {disc.name.length > 0 ? disc.name : "No Name"}
                        </td>
                        <td
                          className="table-cell"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            width: "100%",
                            flexDirection: "row",
                          }}
                        >
                          {disc.claimBy}
                          {new Date(disc.claimBy!) < new Date() && (
                            <Tooltip
                              title="Needs Review"
                              style={{
                                margin: "unset !important",
                                padding: "unset !important",
                                minHeight: "unset !important",
                                minWidth: "unset !important",
                              }}
                            >
                              <IconButton
                                style={{
                                  margin: "unset !important",
                                  padding: "unset !important",
                                  minHeight: "unset !important",
                                  minWidth: "unset !important",
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faCircle}
                                  style={{
                                    color: "red",
                                    width: "10px",
                                    height: "10px",
                                    margin: "auto",
                                    padding: "0px",
                                    filter: "drop-shadow(0px 0px 1px #000)",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                          {disc.status === DiscStateString.New && (
                            <Tooltip title="New">
                              <IconButton>
                                <FontAwesomeIcon
                                  icon={faCircle}
                                  style={{
                                    color: "orange",
                                    width: "10px",
                                    height: "10px",
                                    filter: "drop-shadow(0px 0px 1px #000)",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                          {disc.status !== DiscStateString.New &&
                            new Date(disc.claimBy!) >= new Date() && (
                              <Tooltip title="Unclaimed">
                                <IconButton>
                                  <FontAwesomeIcon
                                    icon={faCircle}
                                    style={{
                                      color: "yellow",
                                      width: "10px",
                                      height: "10px",
                                      filter: "drop-shadow(0px 0px 1px #000)",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                        </td>
                        <td className="table-cell">{disc.disc}</td>
                      </tr>
                      {expandedRow === disc.id && (
                        <tr>
                          <td colSpan={8}>
                            <div>
                              <div className="row-right">
                                {editedDiscID === disc.id ? (
                                  <SaveOutlinedIcon
                                    sx={{
                                      cursor: "pointer",
                                      marginRight: "10px",
                                    }}
                                    onClick={stopEditing}
                                  ></SaveOutlinedIcon>
                                ) : (
                                  <EditOutlinedIcon
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => startEditing(disc)}
                                  ></EditOutlinedIcon>
                                )}
                              </div>
                              <div className="row">
                                {editedDiscID === disc.id ? (
                                  <TextField
                                    id="outlined-uncontrolled"
                                    sx={{
                                      marginTop: "10px",
                                      marginBottom: "10px",
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    label="ID (read-only)"
                                    defaultValue={disc.id}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                ) : (
                                  <p className="detailed-text">
                                    <strong>ID:</strong> {disc.id}
                                  </p>
                                )}
                              </div>
                              <div className="row">
                                {editedDiscID === disc.id ? (
                                  <TextField
                                    id="outlined-uncontrolled"
                                    sx={{
                                      marginTop: "10px",
                                      marginBottom: "10px",
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    label="Course (read-only)"
                                    defaultValue={disc.course}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                ) : (
                                  <p className="detailed-text">
                                    <strong>Course: </strong>
                                    {disc.course}
                                  </p>
                                )}
                              </div>
                              <div className="row">
                                {editedDiscID === disc.id ? (
                                  <TextField
                                    id="outlined-uncontrolled"
                                    sx={{
                                      marginTop: "10px",
                                      marginBottom: "10px",
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    label="Name"
                                    defaultValue={disc.name}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      // Ensure we're updating the editedDisc object, not the original disc
                                      if (editedDisc) {
                                        setEditedDisc({
                                          ...editedDisc, // Spread the current state of editedDisc
                                          name: e.target.value, // Update the name with the new value
                                        });
                                      }
                                    }}
                                  />
                                ) : (
                                  <p className="detailed-text">
                                    <strong>Name: </strong>
                                    {disc.name.length > 0
                                      ? disc.name
                                      : "No Name"}
                                  </p>
                                )}
                              </div>
                              <div className="row">
                                {editedDiscID === disc.id ? (
                                  <TextField
                                    id="outlined-uncontrolled"
                                    sx={{
                                      marginTop: "10px",
                                      marginBottom: "10px",
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    label="Phone Number"
                                    defaultValue={formatPhoneNumber(
                                      disc.phoneNumber
                                    )}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      // Ensure we're updating the editedDisc object, not the original disc
                                      if (editedDisc) {
                                        setEditedDisc({
                                          ...editedDisc, // Spread the current state of editedDisc
                                          phoneNumber: e.target.value, // Update the phoneNumber with the new value
                                        });
                                      }
                                    }}
                                  />
                                ) : (
                                  <p className="detailed-text">
                                    <strong>Phone Number: </strong>
                                    {formatPhoneNumber(disc.phoneNumber)}
                                  </p>
                                )}
                              </div>
                              <div className="row">
                                {editedDiscID === disc.id ? (
                                  <TextField
                                    id="outlined-uncontrolled"
                                    sx={{
                                      marginTop: "10px",
                                      marginBottom: "10px",
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    label="Disc Brand"
                                    defaultValue={disc.brand}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      // Ensure we're updating the editedDisc object, not the original disc
                                      if (editedDisc) {
                                        setEditedDisc({
                                          ...editedDisc, // Spread the current state of editedDisc
                                          brand: e.target.value, // Update the brand with the new value
                                        });
                                      }
                                    }}
                                  />
                                ) : (
                                  <p className="detailed-text">
                                    <strong>Brand: </strong>
                                    {disc.brand}
                                  </p>
                                )}
                              </div>
                              <div className="row">
                                {editedDiscID === disc.id ? (
                                  <TextField
                                    id="outlined-uncontrolled"
                                    sx={{
                                      marginTop: "10px",
                                      marginBottom: "10px",
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    label="Disc Name"
                                    defaultValue={disc.disc}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      // Ensure we're updating the editedDisc object, not the original disc
                                      if (editedDisc) {
                                        setEditedDisc({
                                          ...editedDisc, // Spread the current state of editedDisc
                                          disc: e.target.value, // Update the disc name with the new value
                                        });
                                      }
                                    }}
                                  />
                                ) : (
                                  <p className="detailed-text">
                                    <strong>Disc: </strong>
                                    {disc.disc}
                                  </p>
                                )}
                              </div>
                              <div className="row">
                                {editedDiscID === disc.id ? (
                                  <TextField
                                    id="outlined-uncontrolled"
                                    sx={{
                                      marginTop: "10px",
                                      marginBottom: "10px",
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    label="Color"
                                    defaultValue={disc.color}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      // Ensure we're updating the editedDisc object, not the original disc
                                      if (editedDisc) {
                                        setEditedDisc({
                                          ...editedDisc, // Spread the current state of editedDisc
                                          color: e.target.value, // Update the color with the new value
                                        });
                                      }
                                    }}
                                  />
                                ) : (
                                  <p className="detailed-text">
                                    <strong>Color: </strong>
                                    {disc.color}
                                  </p>
                                )}
                              </div>
                              <div className="row">
                                {editedDiscID === disc.id ? (
                                  <TextField
                                    id="outlined-uncontrolled"
                                    sx={{
                                      marginTop: "10px",
                                      marginBottom: "10px",
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    label="Bin"
                                    defaultValue={disc.bin}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      // Ensure we're updating the editedDisc object, not the original disc
                                      if (editedDisc) {
                                        setEditedDisc({
                                          ...editedDisc, // Spread the current state of editedDisc
                                          bin: e.target.value, // Update the bin with the new value
                                        });
                                      }
                                    }}
                                  />
                                ) : (
                                  <p className="detailed-text">
                                    <strong>Bin: </strong>
                                    {disc.bin}
                                  </p>
                                )}
                              </div>
                              <div className="row">
                                {editedDiscID === disc.id ? (
                                  <TextField
                                    id="outlined-uncontrolled"
                                    sx={{
                                      marginTop: "10px",
                                      marginBottom: "10px",
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    label="Date Found"
                                    defaultValue={disc.dateFound}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      // Ensure we're updating the editedDisc object, not the original disc
                                      if (editedDisc) {
                                        setEditedDisc({
                                          ...editedDisc, // Spread the current state of editedDisc
                                          dateFound: e.target.value, // Update the dateFound with the new value
                                        });
                                      }
                                    }}
                                  />
                                ) : (
                                  <p className="detailed-text">
                                    <strong>Date Found: </strong>
                                    {disc.dateFound}
                                  </p>
                                )}
                              </div>
                              <div className="row">
                                {editedDiscID === disc.id ? (
                                  <TextField
                                    id="outlined-uncontrolled"
                                    sx={{
                                      marginTop: "10px",
                                      marginBottom: "10px",
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    label="Date Texted"
                                    defaultValue={disc.dateTexted}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      // Ensure we're updating the editedDisc object, not the original disc
                                      if (editedDisc) {
                                        setEditedDisc({
                                          ...editedDisc, // Spread the current state of editedDisc
                                          dateTexted: e.target.value, // Update the dateTexted with the new value
                                        });
                                      }
                                    }}
                                  />
                                ) : (
                                  <p className="detailed-text">
                                    <strong>Date Texted: </strong>
                                    {disc.dateTexted}
                                  </p>
                                )}
                              </div>
                              <div className="row">
                                {editedDiscID === disc.id ? (
                                  <TextField
                                    id="outlined-uncontrolled"
                                    sx={{
                                      marginTop: "10px",
                                      marginBottom: "10px",
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    label="Date Claimed"
                                    defaultValue={disc.dateClaimed}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      // Ensure we're updating the editedDisc object, not the original disc
                                      if (editedDisc) {
                                        setEditedDisc({
                                          ...editedDisc, // Spread the current state of editedDisc
                                          dateClaimed: e.target.value, // Update the dateClaimed with the new value
                                        });
                                      }
                                    }}
                                  />
                                ) : (
                                  <p className="detailed-text">
                                    <strong>Date Claimed: </strong>
                                    {disc.dateClaimed}
                                  </p>
                                )}
                              </div>
                              <div className="row">
                                {editedDiscID === disc.id ? (
                                  <TextField
                                    id="outlined-uncontrolled"
                                    sx={{
                                      marginTop: "10px",
                                      marginBottom: "10px",
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    label="Status (read-only)"
                                    defaultValue={disc.status}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                ) : (
                                  <p className="detailed-text">
                                    <strong>Status: </strong>
                                    {disc.status}
                                  </p>
                                )}
                              </div>
                              <div className="row">
                                {editedDiscID === disc.id ? (
                                  <TextField
                                    id="outlined-uncontrolled"
                                    sx={{
                                      marginTop: "10px",
                                      marginBottom: "10px",
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    label="Claim By (read-only)"
                                    defaultValue={disc.claimBy}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                ) : (
                                  <p className="detailed-text">
                                    <strong>Claim By: </strong>
                                    {disc.claimBy}
                                  </p>
                                )}
                              </div>
                              <div className="row">
                                {editedDiscID === disc.id ? (
                                  <TextField
                                    id="outlined-uncontrolled"
                                    sx={{
                                      marginTop: "10px",
                                      marginBottom: "10px",
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    label="Comments"
                                    defaultValue={disc.comments}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      // Ensure we're updating the editedDisc object, not the original disc
                                      if (editedDisc) {
                                        setEditedDisc({
                                          ...editedDisc, // Spread the current state of editedDisc
                                          comments: e.target.value, // Update the comments with the new value
                                        });
                                      }
                                    }}
                                  />
                                ) : (
                                  <p className="detailed-text">
                                    <strong>Comments: </strong>
                                    {disc.comments}
                                  </p>
                                )}
                              </div>
                              {isLoading ? (
                                <div>
                                  <CircularProgress />
                                </div>
                              ) : (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "100%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  {disc.id !== claimedDisc && (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        width: "100%",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <button
                                        className="delete-button"
                                        onClick={() => handleDeleteClick()}
                                      >
                                        <RemoveCircleOutlineOutlinedIcon
                                          sx={{
                                            fontSize: ".75rem",
                                            marginRight: "5px",
                                          }}
                                        />
                                        <p>Delete Disc</p>
                                      </button>
                                      {/* Check if the Claim By is in the past */}
                                      {new Date(disc.claimBy!) < new Date() && (
                                        <button
                                          className="sold-button"
                                          onClick={() =>
                                            listForSale(
                                              disc.id!.toString(),
                                              course.courseName
                                            )
                                          }
                                        >
                                          <SellOutlinedIcon
                                            sx={{
                                              fontSize: ".75rem",
                                              marginRight: "5px",
                                            }}
                                          />
                                          <p>List for Sale</p>
                                        </button>
                                      )}

                                      <button
                                        className="claimed-button"
                                        onClick={() =>
                                          markAsClaimed(disc.id!.toString())
                                        }
                                      >
                                        <BackHandOutlinedIcon
                                          sx={{
                                            fontSize: ".75rem",
                                            marginRight: "5px",
                                          }}
                                        />
                                        <p>Disc Claimed</p>
                                      </button>
                                    </Box>
                                  )}
                                </Box>
                              )}

                              {showDeletePopup && (
                                <DeleteConfirmationPopup
                                  disc={disc}
                                  open={showDeletePopup}
                                  onClose={handleClose}
                                  onConfirm={() => handleConfirmDelete(disc)}
                                />
                              )}
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
            <BackToTopButton />
          </div>
          <Snackbar
            open={showSuccessMessage}
            autoHideDuration={4000}
            onClose={clearSuccessMessage}
          >
            <Alert
              onClose={clearSuccessMessage}
              severity="success"
              variant="filled"
              sx={{ width: "100%" }}
            >
              {successMessage}
            </Alert>
          </Snackbar>

          <Snackbar
            open={showErrorMessage}
            autoHideDuration={4000}
            onClose={clearErrorMessage}
          >
            <Alert
              onClose={clearErrorMessage}
              severity="error"
              variant="filled"
              sx={{ width: "100%" }}
            >
              {errorMessage}
            </Alert>
          </Snackbar>

          <Snackbar
            open={showInformationalMessage}
            autoHideDuration={4000}
            onClose={clearInformationalMessage}
          >
            <Alert
              onClose={clearInformationalMessage}
              severity="info"
              variant="filled"
              sx={{ width: "100%" }}
            >
              {informationalMessage}
            </Alert>
          </Snackbar>
        </div>
      </PullToRefresh>
    </Box>
  );
}

export default Inventory;
