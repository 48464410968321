import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../globals.css";

export interface DiscCounts {
  needsReview: number;
  new: number;
  unclaimed: number;
}

interface LegendProps {
  counts: DiscCounts;
}

function Legend({ counts }: LegendProps) {
  return (
    <div className="legend">
      <div className="legend-item">
        <FontAwesomeIcon icon={faCircle} style={{ color: "red" }} />
        <span>Needs Review ({counts.needsReview})</span>
      </div>
      <div className="legend-item">
        <FontAwesomeIcon icon={faCircle} style={{ color: "orange" }} />
        <span>New ({counts.new})</span>
      </div>
      <div className="legend-item">
        <FontAwesomeIcon icon={faCircle} style={{ color: "yellow" }} />
        <span>Unclaimed ({counts.unclaimed})</span>
      </div>
    </div>
  );
}

export default Legend;
