import { Disc } from "../App";
import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import "../globals.css";
import { Box, ThemeProvider, createTheme } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import "../styles/Inventory.css";

interface DeleteConfirmationPopupProps {
  disc: Disc;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const DeleteConfirmationPopup: React.FC<DeleteConfirmationPopupProps> = ({
  disc,
  open,
  onClose,
  onConfirm,
}) => {
  const sidebarBackgroundColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--sidebar-background-color");
  const theme = createTheme({
    typography: {
      fontFamily: ["Roboto", "Helvetica", "Arial"].join(","),
      fontSize: 16,
      fontWeightLight: 300,
    },
    components: {
      MuiDialog: {
        styleOverrides: {
          paper: {
            backgroundColor: sidebarBackgroundColor,
            overflow: "unset",
            borderRadius: "20px",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            paddingLeft: "0px !important",
            paddingRight: "0px !important",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          },
        },
      },
    },
  });
  return (
    <Box sx={{ borderRadius: "20px" }}>
      <ThemeProvider theme={theme}>
        <Dialog
          open={open}
          onClose={onClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            sx={{
              backgroundColor: "transparent",
              color: "var(--primary-white)",
              margin: "0px",
              borderRadius: "20px",
              fontFamily: "Bebas Neue",
              position: "relative",
              borderTopColor: "var(--primary-red)",
              borderTopWidth: "3.5px",
              borderTopStyle: "solid",
              maxWidth: "810px",
            }}
          >
            <Box
              sx={{
                backgroundColor: "var(--primary-red)",
                borderRadius: "40px",
                height: "60px",
                width: "60px",
                marginTop: "-40px",
                display: "flex",
                marginLeft: "auto",
                marginRight: "auto",
                justifyContent: "center",
              }}
            >
              <DeleteForeverIcon
                fontSize="large"
                color="inherit"
                sx={{
                  margin: "auto",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
            </Box>
            <Box sx={{ position: "absolute", right: "10px", top: "10px" }}>
              <HighlightOffOutlinedIcon
                fontSize="medium"
                color="action"
                onClick={onClose}
                sx={{
                  ":hover": {
                    cursor: "pointer",
                    width: "25px",
                    height: "25px",
                  },
                }}
              />
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you wish to delete the disc "{disc.disc}"?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={onClose}
              color="primary"
              autoFocus
              className="cancel-button"
            >
              Cancel
            </Button>
            <Button
              onClick={onConfirm}
              sx={{ padding: "12px !important" }}
              color="error"
              className="delete-button"
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </Box>
  );
};

export default DeleteConfirmationPopup;
