import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import EnterLostDisc from "./components/EnterLostDisc";
import Inventory from "./components/Inventory";
import "./globals.css";
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Snackbar,
  SnackbarContent,
  Typography,
  keyframes,
} from "@mui/material"; // Import Button and ButtonGroup from MUI
import AdminPanel from "./components/AdminPanel";
import CloseIcon from "@mui/icons-material/Close";

// Define a Disc interface
export interface Disc {
  id?: number;
  course: string;
  name: string;
  disc: string;
  phoneNumber: string;
  bin: string;
  dateFound: string;
  dateTexted?: string | null;
  dateClaimed?: string | null;
  status: DiscStateString;
  comments?: string | null;
  color: string;
  claimBy?: string | null;
  brand?: string | null;
  dateSold?: string | null;
}
export enum DiscStateString {
  New = "NEW",
  Unclaimed = "UNCLAIMED",
  PendingDropoff = "PENDING_DROPOFF",
  PendingStorePickup = "PENDING_STORE_PICKUP",
  PendingCoursePickup = "PENDING_COURSE_PICKUP",
  Claimed = "CLAIMED",
  PickupOverdue = "PICKUP_OVERDUE",
  ForSale = "FOR_SALE",
  Sold = "SOLD",
  SoldOffline = "SOLD_OFFLINE",
  Surrendered = "SURRENDERED",
}

export const openAdminDashboard = () => {
  window.open("https://www.discrescuenetwork.com/request-a-demo");
};

const dateFound1 = "2023-09-29";
const dateFound2 = "2023-10-02";
const dateFound3 = "2023-10-03";
const dateFound4 = "2023-10-03";
const dateFound5 = "2023-12-03";
const dateFound6 = "2023-01-03";
const dateFound7 = "2023-02-03";
const dateFound8 = "2023-02-04";

function App() {
  const [discArray, setDiscArray] = useState<Disc[]>([
    {
      id: 1,
      course: "Tranquility Trails",
      name: "Chris M.",
      disc: "Raptor",
      phoneNumber: "9991234567",
      bin: "1",
      dateFound: dateFound1,
      dateTexted: "2023-10-16",
      dateClaimed: "",
      status: DiscStateString.ForSale,
      comments: "Green.",
      color: "Green",
      claimBy: "2024-01-29",
      brand: "MVP",
    },
    {
      id: 2,
      course: "501 Disc Golf",
      name: "Zack Brady",
      disc: "Instinct",
      phoneNumber: "9991234567",
      bin: "1",
      dateFound: dateFound2,
      dateTexted: "2023-10-17",
      dateClaimed: "",
      status: DiscStateString.ForSale,
      comments: "Neo Plastic",
      color: "Gold",
      claimBy: "2024-02-02",
      brand: "Innova",
    },
    {
      id: 3,
      course: "Girdwood Forest Fair Park",
      name: "Noah Grant",
      disc: "Anax",
      phoneNumber: "9991234567",
      bin: "1",
      dateFound: dateFound3,
      dateTexted: "2023-10-07",
      dateClaimed: "",
      status: DiscStateString.ForSale,
      comments: "ESP",
      color: "Blue",
      claimBy: "2024-02-03",
      brand: "Lonestar",
    },
    {
      id: 4,
      course: "The Beast",
      name: "E. Hopper",
      disc: "King",
      phoneNumber: "9991234567",
      bin: "1",
      dateFound: dateFound4,
      dateTexted: "2023-10-07",
      dateClaimed: "",
      status: DiscStateString.Unclaimed,
      comments: "",
      color: "Purple",
      claimBy: "2024-02-03",
      brand: "Lonestar",
    },
    {
      id: 5,
      course: "Windy Ridge Disc Golf at Mitchusson Park",
      name: "Dan Smith",
      disc: "Escape",
      phoneNumber: "9991234567",
      bin: "1",
      dateFound: dateFound5,
      dateTexted: "2023-10-07",
      dateClaimed: "",
      status: DiscStateString.Unclaimed,
      comments: "Burst",
      color: "Blue",
      claimBy: "2024-04-03",
      brand: "Discmania",
    },
    {
      id: 6,
      course: "DeLaveaga",
      name: "J. Smith",
      disc: "Panther",
      phoneNumber: "9991234567",
      bin: "1",
      dateFound: dateFound6,
      dateTexted: "",
      dateClaimed: "",
      status: DiscStateString.Unclaimed,
      comments: "Champion",
      color: "Pink",
      claimBy: "2024-05-03",
      brand: "Innova",
    },
    {
      id: 7,
      course: "DeLaveaga",
      name: "R. Cornelius",
      disc: "Buzzz",
      phoneNumber: "9991234567",
      bin: "1",
      dateFound: dateFound7,
      dateTexted: "",
      dateClaimed: "2023-10-03",
      status: DiscStateString.Unclaimed,
      comments: "Light blue big z",
      color: "",
      claimBy: "2024-06-03",
      brand: "Innova",
    },
    {
      id: 8,
      course: "Girdwood Forest Fair Park",
      name: "B. Lin",
      disc: "Meta essence",
      phoneNumber: "9991234567",
      bin: "1",
      dateFound: dateFound8,
      dateTexted: "2023-10-07",
      dateClaimed: "",
      status: DiscStateString.Unclaimed,
      comments: "",
      color: "Blue",
      claimBy: "2024-06-04",
      brand: "Innova",
    },
    {
      id: 9,
      course: "Tranquility Trails",
      name: "Archie B.",
      disc: "Explorer",
      phoneNumber: "9991234567",
      bin: "1",
      dateFound: dateFound8,
      dateTexted: "2023-10-07",
      dateClaimed: "",
      status: DiscStateString.New,
      comments: "",
      color: "Yellow",
      claimBy: "2024-06-04",
      brand: "Birdie",
    },
    {
      id: 10,
      course: "Maple Hill",
      name: "A. Smith",
      disc: "Teebird",
      phoneNumber: "9991234567",
      bin: "1",
      dateFound: dateFound8,
      dateTexted: "2023-10-07",
      dateClaimed: "",
      status: DiscStateString.New,
      comments: "",
      color: "Pink",
      claimBy: "2024-06-04",
      brand: "Discraft",
    },
  ]);

  const [showToast, setShowToast] = useState(false); // Initialize toast state

  useEffect(() => {
    setTimeout(() => {
      setShowToast(true);
    }, 10000);
  }, []);

  const shakeAnimation = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
  `;

  const action = (
    <React.Fragment>
      <Button
        color="secondary"
        size="small"
        className="toastButton"
        onClick={() =>
          window.open("https://www.discrescuenetwork.com/request-a-demo")
        }
      >
        Sign up
      </Button>
      <IconButton
        aria-label="close"
        color="inherit"
        sx={{ p: 0.5 }}
        onClick={() => setShowToast(false)}
      >
        <CloseIcon />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Box
      sx={{
        height: "auto",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Routes>
        <Route
          path="/"
          element={
            <AdminPanel discArray={discArray} setDiscArray={setDiscArray} />
          }
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <p className="copywrite">Copyright 2024 Disc Rescue Network LLC</p>
      <Snackbar
        open={showToast}
        // autoHideDuration={4000}
        onClose={() => setShowToast(false)}
        sx={{ fontFamily: "Bebas Neue" }}
      >
        <SnackbarContent
          action={action}
          message="Enjoy the demo!? Sign up for a free trial!"
          sx={{
            width: "100%",
            backgroundColor: "red",
            animation: `${shakeAnimation} 0.6s ease-in-out`,
            fontFamily: "Bebas Neue",
            FontFace: "Bebas Neue",
          }}
        />
        {/* <Alert
          onClose={() => setShowToast(false)}
          severity="success"
          variant="filled"
          sx={{
            width: "100%",
            backgroundColor: "red",
            animation: `${shakeAnimation} 0.6s ease-in-out`,
          }}
        >
          Enjoy the demo!? Sign up for a free trial!
        </Alert> */}
      </Snackbar>
    </Box>
  );
}

export default App;
