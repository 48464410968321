import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CameraRollOutlinedIcon from "@mui/icons-material/CameraRollOutlined";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import EditIcon from "@mui/icons-material/Edit";
import "../styles/EnterLostDisc.css";
import "../globals.css";
import { Alert, Snackbar, TextField, Typography } from "@mui/material";
import CameraComponent from "./CameraComponent";
import ImageDetectionPopup from "./ImageDetectionPopup";
import { courseAndNavProps } from "./Inventory";
import { Disc, DiscStateString } from "../App";

interface DiscData {
  course: string;
  name: string;
  disc: string;
  phoneNumber: string;
  bin: string;
  comments: string;
  dateFound: string;
  color: string;
  brand: string;
}

function EnterLostDisc(props: courseAndNavProps) {
  const {
    course,
    setSelectedIndex,
    createHandleClose,
    lastIndex,
    setLastIndex,
    discArray,
    setDiscArray,
  } = props;

  const [discData, setDiscData] = useState<Disc>({
    course: course.courseName,
    name: "",
    disc: "",
    phoneNumber: "",
    bin: "",
    comments: "",
    dateFound: new Date().toISOString().split("T")[0],
    status: DiscStateString.New,
    color: "",
    brand: "",
  });

  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showCamera, setShowCamera] = useState(false);
  const [selection, setSelection] = useState("");
  const [side, setSide] = useState<string>("");
  const [frontImage, setFrontImage] = useState<string | null>(null);
  const [backImage, setBackImage] = useState<string | null>(null);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [apiResponseData, setApiResponseData] = useState<
    Array<{ text: string; category: string }>
  >([]);
  // Define the predefined categories
  const predefinedCategories: string[] = [
    "Select Category",
    "Name",
    "Disc Brand",
    "Disc Name",
    "Phone Number",
    "Color",
    "Comments",
  ];

  const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  };

  const clearSuccessMessage = () => {
    setShowSuccessMessage(false);
    setSuccessMessage("");
  };

  const clearErrorMessage = () => {
    setShowErrorMessage(false);
    setErrorMessage("");
  };

  // Define a function to map the incoming category to the predefined categories
  function mapToPredefinedCategory(category: string): string {
    if (predefinedCategories.includes(category)) {
      return category; // Matched a predefined category
    } else {
      return ""; // Default to an empty string
    }
  }

  const handlePlaceholderClick = (side: string) => {
    if (side === "front") setSide("front");
    else setSide("back");
    setShowCamera(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    // Enforce numbers-only input for the phone number and bin fields
    if ((name === "phoneNumber" || name === "bin") && !/^\d*$/.test(value)) {
      return;
    }

    setDiscData({ ...discData, [name]: value });
  };

  const handleUpdateCategory = (index: number, category: string) => {
    const updatedData = [...apiResponseData];
    updatedData[index].category = category;
    setApiResponseData(updatedData);
    console.log("Updated data:", updatedData);
  };

  const handleUpdateText = (index: number, text: string) => {
    const updatedData = [...apiResponseData];
    updatedData[index].text = text;
    setApiResponseData(updatedData);
    console.log("Updated data:", updatedData);
  };

  const handleDeleteRow = (index: number) => {
    const updatedData = [...apiResponseData];
    updatedData.splice(index, 1);
    setApiResponseData(updatedData);
    console.log("Updated data:", updatedData);
  };

  const handleImageCapture = async (
    imageData: string,
    side: string,
    retryCount = 0
  ) => {
    clearErrorMessage(); // Clear error message
    clearSuccessMessage(); // Clear success message
    console.log(`Captured ${side} image:`, imageData);
    if (side === "front") {
      setFrontImage(imageData);
      setSide("back");
    } else if (side === "back") {
      setBackImage(imageData);
      setSide("front");
    }

    try {
      const mappedResponse = [
        { text: "9991234567", category: "Phone Number" },
        { text: "Discraft", category: "Disc Brand" },
        { text: "Buzz", category: "Disc Name" },
        { text: "Green", category: "Color" },
        { text: "This is a demo", category: "Unknown" },
        { text: "Edit this for Name", category: "Unknown" },
        { text: "You can delete this", category: "Unknown" },
      ];

      console.log("Mapped response:", mappedResponse);

      setApiResponseData(mappedResponse);
      setShowPopup(true); // Show popup when data is received
    } catch (error) {
      console.error("Error processing image:", error);
      setErrorMessage("Error processing image. Please try again.");
      setShowErrorMessage(true);
    }
  };

  const prefillForm = () => {
    console.log("Prefilling form with data:", apiResponseData);
    // Iterate through the APIResponseData and map categories to input fields
    apiResponseData.forEach((item) => {
      switch (item.category) {
        case "Disc Brand":
          setDiscData((prevData) => ({
            ...prevData,
            brand: item.text,
          }));
          break;
        case "Comments":
          setDiscData((prevData) => ({
            ...prevData,
            comments: item.text,
          }));
          break;
        case "Phone Number":
          setDiscData((prevData) => ({
            ...prevData,
            phoneNumber: item.text,
          }));
          break;
        case "Name":
          setDiscData((prevData) => ({
            ...prevData,
            name: item.text,
          }));
          break;
        case "Disc Name":
          setDiscData((prevData) => ({
            ...prevData,
            disc: item.text,
          }));
          break;
        case "Color":
          setDiscData((prevData) => ({
            ...prevData,
            color: item.text,
          }));
          break;
        default:
          // If there is no match, do nothing
          break;
      }
    });

    // Close the popup after prefilling the form
    setShowPopup(false);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // if (!frontImage || !backImage) {
    //   // Display an error message to the user or prevent form submission
    //   // Example: setError("Please capture both front and back images.");
    //   return;
    // }

    clearErrorMessage(); // Clear error message
    clearSuccessMessage(); // Clear success message
    setIsLoading(true); // Set loading to true when the request is initiated

    const formData = new FormData();
    Object.entries(discData).forEach(([key, value]) => {
      formData.append(key, value);
    });

    discData.dateFound = new Date().toISOString().split("T")[0];
    discData.course = course.courseName;
    discData.status = DiscStateString.New;
    discData.id = discArray.length + 1;
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 120);
    discData.claimBy = currentDate.toISOString().split("T")[0];
    setDiscArray([...discArray, discData]);

    // Set success message with the ID of the row from the DB
    setSuccessMessage(`Disc added with ID ${discData.id}`);
    setShowSuccessMessage(true);

    // Clear the form and loading state
    setDiscData({
      course: course.courseName,
      name: "",
      disc: "",
      phoneNumber: "",
      bin: discData.bin, //don't overwrite the bin number
      comments: "",
      dateFound: new Date().toISOString().split("T")[0],
      status: DiscStateString.New,
      color: "",
      brand: discData.brand, //don't overwrite the brand
    });
    setIsLoading(false);
  };

  const handleCameraButtonClick = () => {
    setSelection("camera");
    setShowCamera(true);
  };

  const handleManualButtonClick = () => {
    setSelection("manual");
    setShowCamera(false);
  };

  const resetForm = (event: React.FormEvent) => {
    event.preventDefault();
    setDiscData({
      course: course.courseName,
      name: "",
      disc: "",
      phoneNumber: "",
      bin: "",
      comments: "",
      dateFound: new Date().toISOString().split("T")[0],
      status: DiscStateString.New,
      color: "",
      brand: "",
    });
    setFrontImage(null);
    setBackImage(null);
  };

  const [lastIndexPageName, setLastIndexPageName] = useState<string>("");

  useEffect(() => {
    if (lastIndex === 0) {
      setLastIndexPageName("Enter Lost Disc");
    } else if (lastIndex === 1) {
      setLastIndexPageName("Inventory");
    } else if (lastIndex === 2) {
      setLastIndexPageName("For Sale");
    } else {
      setLastIndexPageName("Error");
    }
  }, [lastIndex]);

  return (
    <div className="lost-disc-container">
      {lastIndex !== -1 && (
        <div className="row-back">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="back-icon"
            onClick={() => {
              setLastIndex(-1);
              setSelectedIndex(lastIndex);
              createHandleClose();
            }}
          >
            <path d="M21 11H6.414l5.293-5.293-1.414-1.414L2.586 12l7.707 7.707 1.414-1.414L6.414 13H21z"></path>
          </svg>

          <p
            className="lastIndexPageName"
            onClick={() => {
              setLastIndex(-1);
              setSelectedIndex(lastIndex);
              createHandleClose();
            }}
          >
            {lastIndexPageName}
          </p>
        </div>
      )}
      <h1 className="header">{course.courseName} L & F</h1>
      <h1 className="EnterLostDisc">ENTER LOST DISC</h1>
      <div className="button-container">
        <button
          className={
            showCamera ? "button-options-red active" : "button-options-red"
          }
          onClick={handleCameraButtonClick}
        >
          <CameraRollOutlinedIcon className="button-icon-camera" />
          <span className="button-text">USE CAMERA</span>
        </button>
        <button
          className={
            showCamera ? "button-options-black" : "button-options-black active"
          }
          onClick={handleManualButtonClick}
        >
          <CreateOutlinedIcon className="button-icon-pencil" />
          <span className="button-text">Manual Input</span>
        </button>
      </div>

      <div className="use-camera-container">
        {showCamera && (
          <CameraComponent
            onCapture={(imageData, side) => handleImageCapture(imageData, side)}
            side={side}
            setSide={setSide}
            switchToManual={handleManualButtonClick}
          />
        )}

        {selection === "manual" && (
          <form
            onSubmit={handleSubmit}
            style={{ width: "75%", margin: "auto", marginTop: "20px" }}
          >
            <div className="form-group">
              <TextField
                type="text"
                id="name"
                name="name"
                className="input-field"
                label="Name"
                value={discData.name}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <TextField
                type="tel" // Use type="tel" to display the numeric keyboard on mobile
                id="phoneNumber"
                name="phoneNumber"
                value={discData.phoneNumber}
                className="input-field"
                label="Phone Number"
                onChange={handleChange}
                placeholder="xxx-xxx-xxxx"
              />
            </div>
            <div className="form-group">
              <TextField
                type="text"
                id="brand"
                name="brand"
                className="input-field"
                label="Brand"
                value={discData.brand}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <TextField
                type="text"
                id="disc"
                name="disc"
                className="input-field"
                label="Disc"
                value={discData.disc}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <TextField
                type="text"
                id="color"
                name="color"
                className="input-field"
                label="Color"
                value={discData.color}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <TextField
                type="number" // Use type="number" to display the numeric keyboard on mobile
                id="bin"
                name="bin"
                className="input-field"
                label="Bin"
                value={discData.bin}
                onChange={handleChange}
              />
            </div>
            <div className="form-group custom-comments">
              <TextField
                type="text" // Use type="number" to display the numeric keyboard on mobile
                id="comments"
                name="comments"
                className="input-field"
                label="Comments"
                value={discData.comments}
                onChange={handleChange}
              />
            </div>
            <div className="form-buttons-container">
              <button className="reset-format" onClick={resetForm}>
                Reset Form
              </button>
              <button
                type="submit"
                className={`submit-button ${isLoading ? "loading" : ""}`}
              >
                {isLoading ? <CircularProgress /> : "Submit Disc"}
              </button>
            </div>
          </form>
        )}

        {selection && (
          <div className="image-placeholder-container">
            {/* Front Image */}
            <div
              className="image-container"
              onClick={() => handlePlaceholderClick("front")}
            >
              {frontImage ? (
                <img src={frontImage} alt="Front" />
              ) : (
                <div className="image-placeholder">
                  <CameraAltIcon className="camera-icon" />
                  {/* <span>Capture</span> */}
                </div>
              )}
            </div>
            <div className="image-placeholder-label">Front</div>

            {/* Back Image */}
            <div
              className="image-container"
              onClick={() => handlePlaceholderClick("back")}
            >
              {backImage ? (
                <img src={backImage} alt="Back" />
              ) : (
                <div className="image-placeholder">
                  <CameraAltIcon className="camera-icon" />
                  {/* <span>Capture Back</span> */}
                </div>
              )}
            </div>
            <div className="image-placeholder-label">Back</div>
          </div>
        )}
      </div>

      {showPopup && (
        <ImageDetectionPopup
          data={apiResponseData}
          onClose={() => setShowPopup(false)}
          onUpdateCategory={handleUpdateCategory}
          onUpdateText={handleUpdateText}
          prefillForm={prefillForm}
          onDelete={handleDeleteRow}
          categories={predefinedCategories}
        />
      )}

      {/* {selection === "camera" && (
        <Typography
          sx={{
            color: "black",
            fontSize: "1.5rem",
            marginBottom: "10px",
          }}
        >
          Use Camera will be available soon. Please check back later.
        </Typography>
      )} */}

      <Snackbar
        open={showSuccessMessage}
        autoHideDuration={4000}
        onClose={clearSuccessMessage}
      >
        <Alert
          onClose={clearSuccessMessage}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        open={showErrorMessage}
        autoHideDuration={4000}
        onClose={clearErrorMessage}
      >
        <Alert
          onClose={clearErrorMessage}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>

      {showCamera && (
        <p className="disclaimer-circle ">
          The circle on the screen is an indicator of where the disc should be
          placed but the system will read any text in the frame. To avoid any
          additional text being added please place the disc on a flat surface.
        </p>
      )}
    </div>
  );
}

export default EnterLostDisc;
